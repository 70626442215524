body {
  margin: 0;
  --twitter-color: #50b7f5;
  --twitter-background: #e6ecf0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h3 {
  font-size: 20px !important;
  font-weight: 800 !important;
}

a {
  color:black !important;
}

/* .sidebar {
  border-right: 1px solid var(--twitter-background);
  height: 90vh;
} */

i.fab.fa-twitter.fa-2x.text-info.pad {
  margin-left: 20px;
  margin-bottom: 20px;
}

.sidebarOption {
  cursor: pointer;
}
.sidebarOption:hover {
  background-color: #e8f5fe;
  border-radius: 30px;
  color: var(--twitter-color);
  transition: color 100ms ease-out;
  
}

.sidebarOption__active {
  color: var(--twitter-color);
}

button.sidebar_tweet.btn.btn-outline-info.btn-block {
  border-radius: 30px;
  margin-top: 20px;
  margin-left: 20px;
  max-width: 80%;
}

.feed {
  border-right: 1px solid var(--twitter-background);
  border-left: 1px solid var(--twitter-background);
  height: 100vh;
  overflow-y: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.feed::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.feed::-webkit-scrollbar {
  display: none;
}


.feed__header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
  border: 1px solid var(--twitter-background);
  padding: 15px 20px;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.tweetbox{
  padding-bottom: 10px;
  padding-right: 10px;
  border-bottom: 8px solid var(--twitter-background);
}

.tweetbox__input{
  display: flex;
  padding: 20px;
}

.tweetbox__input > input{
  flex: 1;
  margin-left: 20px;
  font-size: 20px;
  border: none;

}

.tweetbox__button {
  background-color: var(--twitter-color) !important;
  border: none !important;
  color: white !important;
  font-weight: 900 !important;
  text-transform: inherit !important;
  border-radius: 30px !important;
  width: 80px;
  height: 40px !important;
  margin-top: 20px !important;
}

.tweetbox__button__delete {
  background-color: red !important;
  border: none !important;
  color: white !important;
  font-weight: 900 !important;
  text-transform: inherit !important;
  border-radius: 30px !important;
  width: 80px;
  height: 40px !important;
  margin-top: 20px !important;
  margin-left: 10px !important;
}

.tweetbox__upload{
  background-color: var(--twitter-background) !important;
  border: none !important;
  color: black !important;
  font-weight: 900 !important;
  text-transform: inherit !important;
  border-radius: 30px !important;
  width: 40px;
  height: 40px !important;
  margin-top: 20px !important;
  margin-left: 10px !important;
}

.tweetbox__upload:hover{
  background-color: var(--twitter-color) !important;
  color: white !important;
  transition:all 100ms ease-out;
}

.verified {
  color:var(--twitter-color);
  margin-right: 5px;
}

.comment__icon:hover{
  color: var(--twitter-color);
  cursor: pointer;
  transition: color 100ms ease-out;
}

.retweet__icon:hover{
  color: green;
  cursor: pointer;
  transition: color 100ms ease-out;
}

.heart__icon:hover{
  color: rgb(255, 0, 157);
  cursor: pointer;
  transition: color 100ms ease-out;
}

.widgets{
  margin: 10px 20px;
}

.widgets__input {
  display: flex !important;
  background-color: var(--twitter-background) !important;
  border-radius: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.widgets__input > input{
  background-color: var(--twitter-background);
  border: none;
  border-radius: 20px;
  flex: 1;
}

.widgets__search{
  margin: 10px;
}

.list-group{
  border-radius: 10px !important;
  background-color:var(--twitter-background) !important;
  color: black !important;
  margin-bottom: 20px !important;
}

.list-group-item{
  background-color: var(--twitter-background) !important;
  color: black !important;
  border-bottom: 1px solid #888A96 !important;
  border-top: 1px solid #888A96 !important;
}

.list-group-item:hover{
  cursor: pointer;
  background-color: #A2A4B3 !important;
}

.list-group > h2{
  font-size: 18px;
  font-weight: 800;
  margin: 10px auto;

}

.widgets__button {
  background-color: var(--twitter-color) !important;
  border: none !important;
  color: white !important;
  border-radius: 30px !important;
}

.widgets__button__following{
  background-color: var(--twitter-color) !important;
  border: none !important;
  color: white !important;
  border-radius: 30px !important;
}

.widgets__button__following:hover{
  background-color:black !important;
  transition: background-color 500ms ease-out !important;
}

.widgets__button__following:hover::before{
  content: "Unfollow" !important;
}

.widgets__button__following::after{
  content: "Following";
}

.widgets__button__following:hover::after{
  content: "" !important;
}

.widgets__profile:hover {
  cursor: pointer;
}

.widgets__small {
  position: relative;
  top : -20px;
}

.avatar__medium__profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: relative;
  top: -80px;
  left: 50px;
  border: 5px solid white;
}

.profile__screen_border{
  border-right: 5px solid var(--twitter-background);
  height: auto;
  min-height: 100vh;
}

.profile__header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
  border: 1px solid var(--twitter-background);
  padding: 15px 20px;
  max-height: 80px;
}

.profile__header__user {
  display: flex;
}

.profile__header__user > h3 {
  padding-left: 20px;
}

.profile__header__user > a {
  border: none !important;
  background-color: var(--twitter-color) !important;
  color: white !important;
  border-radius: 30px !important;
}

.profile__header > small {
  position: relative;
  top: -15px;
  left: 75px;
}

.profile__edit {
  margin-top: 10px;
  height: 40px !important;
  background-color: var(--twitter-background);
  border-radius: 10px;
  border: 1px solid gray;
}

.profile__info {
  position: relative;
  top: -40px;
  margin: 20px;
}

.profile__info > small {
  position: relative;
  top: -10px;
}

.profile__follow {
  display: flex;
}

.profile__follow > a {
  margin-right: 20px;
}

.profile__link {
  cursor: pointer !important;
}

.comment {
  position: relative;
  top: -30px;
}

.login__button {
  border-radius: 30px !important;
}

.login__form {
  margin-top: 40px !important;
}

.post {
  border-bottom: 3px solid var(--twitter-background) !important;
}

.tweet__liked {
  color:rgb(255, 0, 157) !important;
  transition: color 1000ms ease-out;
}

.tweet__details {
  cursor: pointer;
}


.upload__filename{
  position: relative;
  left: -25%;
  bottom: -30px;
  color: green;
  font-size: medium;
}